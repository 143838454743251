import styled from 'styled-components'

export const StyledPopup = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(16, 53, 110, 0.89);
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`
export const Content = styled.div`
  height: 400px;
  width: 277px;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  @media (min-width: 768px) {
    width: 623px;
    height: 517px;
  }
`

export const Close = styled.a`
  width: 56px;
  height: 56px;
  display: flex;
  float: right;
`

export const Right = styled.a`
  width: fit-content;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 45%;
  left: 92%;
  bottom: 0;
  right: 0;
`

export const RightArrow = styled.img`
  width: 9px;
`

export const Left = styled.a`
  width: fit-content;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 45%;
  left: -92%;
  bottom: 0;
  right: 0;
`

export const LeftArrow = styled.img`
  width: 9px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`

export const CloseIcon = styled.img`
  width: 50px;
`

export const PopupBody = styled.div`
  margin-top: 64px;
  text-align: center;
  font-family: Montserrat;
  padding-left: 36px;
  padding-right: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
`

export const StepTitle = styled.span`
  color: #0b4793;
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const StepDesc = styled.p`
  color: #385cad;
  font-size: 20px;
  margin-bottom: 0em;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`

export const PopupFooter = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin: 0 auto;
  width: fit-content;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 41px;
  }
`
export const ImgStepOne = styled.img`
  width: 200px;
`

export const ImgStepTwo = styled.img`
  width: 200px;
`

export const ImgStepThree = styled.img`
  width: 200px;
`

export const ButtonArea = styled.div`
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: 19px;

  @media (min-width: 768px) {
    margin-top: 90px;
  }
`

export const ButtonPrimary = styled.button`
  width: 236px;
  height: 48px;
  color: #fff;
  font-weight: 600;
  background: #0b4793;
  border-color: #0b4793;
  border-radius: 34px;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

  :hover {
    background: #0b4793;
    border-color: #0b4793;
    cursor: pointer;
  }

  :disabled {
    border: none;
    background-color: #d2d2d2;
    color: white;
  }
`
