import React from 'react'
import {
  StyledPopup,
  Content,
  Close,
  Right,
  RightArrow,
  Left,
  LeftArrow,
  CloseIcon,
  PopupBody,
  StepTitle,
  StepDesc,
  PopupFooter,
  ImgStepOne,
  ImgStepTwo,
  ImgStepThree,
  ButtonArea,
  ButtonPrimary
} from './styledComponents'
import closeIcon from '../../data/assets/images/right.png'
import arrow from '../../data/assets/images/RightArrow.png'
import step1 from '../../data/assets/images/walk-step1.png'
import step2 from '../../data/assets/images/walk-step2.png'
import step3 from '../../data/assets/images/walk-step3.png'

type props = {
  onCancel: () => void
  addStep: () => void
  subStep: () => void
  step: number
}

const WalkthroughSerialPOS: React.FC<props> = ({
  onCancel,
  addStep,
  subStep,
  step
}) => {
  const stepContent = [
    {
      Title: '¿Dónde esta el número de serie de mi terminal?',
      Content: `
      Abre tu Punto de Venta
      `
    },
    {
      Title: '¿Dónde esta el número de serie de mi terminal?',
      Content: `
      Saca el rollo de tickets
      `
    },
    {
      Title: '¿Dónde esta el número de serie de mi terminal?',
      Content: `
      Escanea el número de serie
      `
    }
  ]

  return (
    <StyledPopup>
      {
        <Content>
          <Close onClick={onCancel}>
            <CloseIcon src={closeIcon} />
          </Close>

          {step < 2 ? (
            <Right onClick={addStep}>
              <RightArrow src={arrow} />
            </Right>
          ) : null}
          {step > 0 ? (
            <Left onClick={subStep}>
              <LeftArrow src={arrow} />
            </Left>
          ) : null}
          <PopupBody>
            <StepTitle>{stepContent[step].Title}</StepTitle>
            <StepDesc>{stepContent[step].Content}</StepDesc>
          </PopupBody>

          <PopupFooter>
            {step === 0 ? (
              <span>
                <ImgStepOne src={step1}></ImgStepOne>
              </span>
            ) : null}

            {step === 1 ? (
              <span>
                <ImgStepTwo src={step2}></ImgStepTwo>
              </span>
            ) : null}

            {step === 2 ? (
              <span>
                <ImgStepThree src={step3}></ImgStepThree>
                <ButtonArea>
                  <ButtonPrimary onClick={onCancel}>ACEPTAR</ButtonPrimary>
                </ButtonArea>
              </span>
            ) : null}
          </PopupFooter>
        </Content>
      }
    </StyledPopup>
  )
}

export default WalkthroughSerialPOS
