//Packages
import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { toast, ToastContainer } from 'react-toastify'

//Components
import VerifyAccountComponent from '../../components/VerifyAccount'
import ModalResult from '../../components/Shared/ModalResult'
import ModalResponse from '../../components/Shared/ModalResponse'

//Configuracion
import '../../../config/amplify'

// Constants
import constants from '../../data/constants'

//Styled Components
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'

//Types
import AuthTypes from '../../types/AuthTypes'

import WalkthroughSerialPOS from '../../components/WalkthroughSerialPOS'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_USER_POS_ID,
  GET_USER_SESSION
} from '../../graphql/queries/partner'
import { getParameter } from '../../utils/queryString'
import { navigate } from 'gatsby-link'
import { UPDATE_COGNITO_USER_NAME } from '../../graphql/mutation/partner'
import { resendSignUpCode } from 'aws-amplify/auth'
import useErrorHandler from '../../hooks/useErrorHandler'

type propsResult = {
  isModalVisible: boolean
  content: any
}

const resultInitialState: propsResult = {
  isModalVisible: false,
  content: <></>
}

const VerifyAccountContainer: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [formVerifyAccount] = Form.useForm<AuthTypes.VerifyAccount>()
  const [serialState, setSerialState] = useState(false)
  const [result, setResult] = useState(resultInitialState)
  const [numberAttemps, setNumberAttemps] = useState(0)
  const [submitFirstLogin, { data: dataUser, loading: dataUserLoading }] =
    useLazyQuery(GET_USER_SESSION)

  const [isPopupVisible, setPopup] = useState(false)
  const [step, changeStep] = useState(0)

  const [apolloError, setApolloError] = useState<ApolloError>()
  const errorMessage = useErrorHandler(apolloError, loading)

  const [onSubmit, { data: userPosData, loading: userPosLoading }] =
    useLazyQuery(GET_USER_POS_ID)

  const [
    updateUserCognito,
    { data: dataUserCognito, loading: dataUserCognitoLoading }
  ] = useMutation(UPDATE_COGNITO_USER_NAME)

  const changeModal = () => {
    changeStep(0)
    setPopup(!isPopupVisible)
  }

  const addStep = () => {
    const newStep = step + 1 === 3 ? 0 : step + 1
    changeStep(newStep)
  }

  const subStep = () => {
    const newStep = step - 1 <= 0 ? 0 : step - 1
    changeStep(newStep)
  }

  const handleCancel = () => setResult(resultInitialState)

  useEffect(() => {
    if (dataUser && !dataUserLoading) {
      const newPhoneNumber = getParameter('userName')
      if (dataUser.IsFirstLogin.FirstLogin == true) {
        updateUserCognito({
          variables: {
            userName: userPosData.CheckNumberByPSN.cognito_username,
            oldPhoneNumber: userPosData.CheckNumberByPSN.mobile_phone,
            newPhoneNumber: newPhoneNumber
          }
        })
      } else {
        navigate('/auth/help')
      }
    }
  }, [dataUser])

  useEffect(() => {
    if (userPosData && !userPosLoading) {
      if (userPosData.CheckNumberByPSN.message !== 'NOT FOUND') {
        submitFirstLogin({
          variables: {
            userName: userPosData.CheckNumberByPSN.mobile_phone
          }
        })
      } else {
        navigate(`/auth/help`)
      }
    }
  }, [userPosData])

  useEffect(() => {
    if (dataUserCognito && !dataUserCognitoLoading) {
      if (
        dataUserCognito.UpdateCognitoUserName.status === 'User has been updated'
      ) {
        sendOTP()
      } else {
        navigate(`/auth/help`)
      }
    }
  }, [dataUserCognito])

  useEffect(() => {
    if (errorMessage && apolloError) {
      setResult({
        isModalVisible: true,
        content: (
          <ModalResult
            key={`success-${Math.random()}`}
            status={'error'}
            subTitle={errorMessage}
            extra={
              <StyledButtonPrimary
                type="button"
                onClick={() => {
                  handleCancel()
                  navigate(`/auth/help`)
                }}
              >
                Aceptar
              </StyledButtonPrimary>
            }
          />
        )
      })
    }
  }, [errorMessage, apolloError])

  const handleVerifyAccount = async (data: any) => {
    setLoading(true)
    const { serie, repeatSerial } = data
    if (serie === repeatSerial) {
      onSubmit({
        variables: { posID: `${formVerifyAccount.getFieldValue('serie')}` }
      })
    } else {
      setLoading(false)
      setNumberAttemps(numberAttemps + 1)
      toast.error('Los números de serie no coinciden. Intenta de nuevo')
      if (numberAttemps === 2) {
        setTimeout(() => {
          navigate('/auth/help')
        }, 2000)
      }
    }
  }

  const handleChangeSerial = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { regularExpressions } = constants
    const serial = new RegExp(regularExpressions['serie']).test(e.target.value)
    setSerialState(serial)
  }

  const handleCopyPaste = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    return false
  }

  const sendOTP = async () => {
    await resendSignUpCode({ username: `+52${getParameter('userName')}` })
      .then((success) => {
        const { Destination } = success.CodeDeliveryDetails
        const message =
          success.CodeDeliveryDetails.AttributeName === 'email'
            ? `Se ha enviado un código de verificación vía Email a la dirección ${Destination}`
            : `Se ha enviado un código de verificación vía SMS al número ${Destination}`

        setResult({
          isModalVisible: true,
          content: (
            <ModalResult
              key={`success-${Math.random()}`}
              status={'success'}
              subTitle={message}
              extra={
                <StyledButtonPrimary
                  type="button"
                  onClick={() =>
                    navigate(
                      `/auth/reset-password?userName=${getParameter(
                        'userName'
                      )}&code=true`
                    )
                  }
                >
                  Aceptar
                </StyledButtonPrimary>
              }
            />
          )
        })
      })
      .catch((error) => {
        setLoading(false)
        setApolloError(error)
      })
  }

  return (
    <>
      <VerifyAccountComponent
        onFinish={handleVerifyAccount}
        form={formVerifyAccount}
        loading={loading}
        serialState={serialState}
        onChangeSerial={handleChangeSerial}
        handleCopyPaste={handleCopyPaste}
        handlePopUp={changeModal}
        titleForm={'NO HEMOS ENCONTRADO TU NÚMERO DE TELÉFONO'}
        subtitleForm={
          'Por favor ingresa el número de serie de tu POS para poder continuar con tu registro.'
        }
      />
      <ModalResponse
        isModalVisible={result.isModalVisible}
        content={result.content}
        onCancel={handleCancel}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isPopupVisible ? (
        <WalkthroughSerialPOS
          onCancel={changeModal}
          step={step}
          addStep={addStep}
          subStep={subStep}
        />
      ) : null}
    </>
  )
}

export default VerifyAccountContainer
