// Packages
import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import { Form, Row, Col, Input, FormInstance } from 'antd'

// Constants
import constants from '../../data/constants'

// Styled Components
import { FooterMessage, DisplayFunctionComponent } from './styledComponents'

// Utils
import { getParameter } from '../../utils/queryString'
import { StyledButtonPrimary } from '../Shared/Button/styledComponent'

const LazyFormContent = loadable(() => import('../FormContent'))

type props = {
  onFinish: any
  onChangeSerial: any
  handleCopyPaste: any
  titleForm: string
  subtitleForm: string
  loading: boolean
  serialState: any
  handlePopUp: any
  form: FormInstance
}

const VerifyAccountComponent: React.FC<props> = ({
  onFinish,
  onChangeSerial,
  form,
  loading,
  serialState,
  handlePopUp,
  handleCopyPaste,
  titleForm,
  subtitleForm
}: any) => {
  const userName = getParameter('userName')

  useEffect(() => {
    form.setFieldsValue({
      userName: userName
    })
  }, [])

  const content = () => {
    const { regularExpressions } = constants
    return (
      <>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              label="Número de teléfono"
              name="userName"
              rules={[
                { required: true, message: 'Ingresa tu número a 10 dígitos' }
              ]}
              hidden
            >
              <Input
                placeholder="Número de teléfono"
                value={form.getFieldValue('userName')}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              label="Número de serie de tu terminal"
              name="serie"
              rules={[
                {
                  required: true,
                  message: 'El formato no es el correcto, vuelva a intentar',
                  pattern: RegExp(regularExpressions['serie'])
                }
              ]}
            >
              <Input
                maxLength={16}
                onPaste={handleCopyPaste}
                onCopy={handleCopyPaste}
                placeholder="16 dígitos"
                onChange={onChangeSerial}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <DisplayFunctionComponent displayFunction={serialState}>
              <Form.Item
                label="Confirmar número de serie"
                name="repeatSerial"
                rules={[
                  {
                    required: true,
                    message: 'El formato no es el correcto, vuelva a intentar',
                    pattern: RegExp(regularExpressions['serie'])
                  }
                ]}
              >
                <Input
                  maxLength={16}
                  placeholder="16 dígitos"
                  disabled={!serialState}
                  onPaste={handleCopyPaste}
                  onCopy={handleCopyPaste}
                />
              </Form.Item>
            </DisplayFunctionComponent>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item>
              <StyledButtonPrimary type="submit" disabled={!serialState}>
                ENTRAR
              </StyledButtonPrimary>
            </Form.Item>
            <FooterMessage onClick={handlePopUp}>
              ¿Donde está el número de serie?
            </FooterMessage>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <LazyFormContent
        form={form}
        onFinish={onFinish}
        content={content()}
        titleForm={titleForm}
        loading={loading}
        subtitleForm={subtitleForm}
      />
    </>
  )
}

export default VerifyAccountComponent
