import { Form, Button } from 'antd'
import styled from 'styled-components'

interface Login {
  img: string
}

export const StyledAuthContainer = styled.div<Login>`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`
export const Img = styled.img`
  width: 100%;
`
export const StyledForm = styled(Form)`
  h2 {
    font-weight: 700;
    text-transform: uppercase;
    color: #385cad;
    text-align: center;
    margin: 10px;
  }

  h6 {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin: 10px;
  }

  .ant-input-affix-wrapper input {
    height: 35px;
  }

  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    font-weight: 600;
  }

  @media all and (max-width: 480px) {
    padding: 25px;
  }
`
export const ButtonPrimary = styled(Button)`
  color: #fff;
  font-weight: 600;
  background: #0b4793;
  border-color: #0b4793;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

  :hover {
    background: #0b4793;
    border-color: #0b4793;
  }

  :disabled {
    border: none;
    background-color: #d2d2d2;
    color: white;
  }
`

export const DispositiveValidate = styled.div`
  @media (min-width: 475px) {
    display: none;
  }
`

export const DisplayFunctionComponent = styled.div<{
  displayFunction: boolean
}>`
  ${({ displayFunction }) => {
    if (displayFunction) {
      return 'display: block'
    }
    return 'display: none'
  }}
`

export const ButtonSquare = styled(Button)`
  color: #fff;
  font-weight: 600;
  background: #0b4793;
  height: 3em !important;
  width: 100%;
  border-radius: 6px !important;
  border-color: #0b4793;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

  :hover {
    background: #0b4793;
    border-color: #0b4793;
  }

  :disabled {
    border: none;
    background-color: #d2d2d2;
    color: white;
  }
`

export const IconComponent = styled.img`
  height: 2em;
`

export const ButtonText = styled(Button)`
  background-color: transparent;
  border: none;
  color: #0b4793;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: none;
  margin-top: 10px;
  height: 40px;
  border-radius: 50px;
  width: 100%;

  :hover {
    background: transparent;
    border: 2px solid #0b4793;
    color: #0b4793;
  }
`
export const ButtonDefault = styled(Button)`
  background-color: transparent;
  border: 2px solid #0b4793;
  color: #0b4793;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: none;
  margin-top: 10px;

  :hover {
    background: #0b4793;
    border: 2px solid #0b4793;
    color: white;
  }
`

export const FooterMessage = styled.h6`
  font-size: 11px !important;
  color: #0b4793;
  margin: 0em !important;
  cursor: pointer;
`

export const PhoneContent = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 150px;
`

export const PhoneIcon = styled.img`
  width: 32px;
`
export const PhoneText = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  color: #385cad;
  text-align: center;
  margin: 10px 9px 10px 9px;
  display: inline-block;
`
